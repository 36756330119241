import React from 'react'
import { Link } from 'react-router-dom'
import * as Sentry from "@sentry/react"
import Example from "./Example"

function FallbackComponent() {
    return <div><p>An error has occurred</p></div>;
  }
  
  const myFallback = <FallbackComponent />;

function About() {
    return (
        <div>
            <Link to="/"> HOME </Link>
            <h1>This is the about page</h1>

            <Sentry.ErrorBoundary fallback={myFallback} showDialog>
                <Example  />
            </Sentry.ErrorBoundary>

        </div>
    )
}



export default About