import './App.css';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


import { Routes, Route } from "react-router-dom"
import Home from "./components/Home"
import About from "./components/About"
import Contact from "./components/Contact"


Sentry.init({
  // dsn: "https://45c95c7f08e34ce1a857601fb3328c16@o954820.ingest.sentry.io/4504922783154176", // Org: Liv-togethr, Project: Javascript-React 
  // dsn: "https://746e7120aaf64aef88a9a2f4745db7e7@o1134611.ingest.sentry.io/4504101784846336", // Org: Beetlejuice, Project: react-test-netlify
  dsn: "https://1a7918a28ee8d13a7eee97f8db2e4f42@o1134611.ingest.sentry.io/4505914456932352", // Org:Beetlejuice, Project: test-cloudflare-javascript-react
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1,
  debug:true,
  sampleRate: 1,
  release: "Test-Cloudflare-Workers"
  // release: process.env.COMMIT_REF,

    // Called for message and error events
    // beforeSend(event) {
    //     // Modify or drop the event here
    //     event.message = "Overwrite GEO, empty string"
    //     if(event.user){
    //       console.log("USER: ", event.user)
    //     }
    //   return event;
    // },

});

function App() {


  Sentry.configureScope((scope) => scope.setTransactionName("Words go here"));

  return (


    <div className="App">
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="about" element={ <About/> } />
        <Route path="contact" element={ <Contact/> } />
      </Routes>
    </div>
      );
}

export default App;
