import React from 'react'
// import { Link } from 'react-router-dom'

const undefinedMethod = "defined"

function Example() {
    return (
        <div>
            <button onClick={() => undefinedMethod()}>
                Undefined Error
            </button> 
        </div>
    )
}

export default Example