import React from 'react'
import { Link } from 'react-router-dom'

function Contact() {
    return (
        <div>
            <Link to="/"> HOME </Link>
            <h1>This is the contact page</h1>
        </div>
    )
}

export default Contact